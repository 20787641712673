import { ForwardedRef, forwardRef } from 'react';

import { OptionModel } from '@/common/models/OptionModel';

import { IAmInputRenderProps, InputRenderer } from '../Renderers/InputRenderer';
import { Select, SelectProps } from './_Components';

export interface FormSelectProps<TOption extends OptionModel = OptionModel>
  extends Omit<
      SelectProps<TOption>,
      'labelProps' | 'descriptionProps' | 'withAsterisk'
    >,
    IAmInputRenderProps<HTMLButtonElement> {}

function _FormSelect<TOption extends OptionModel = OptionModel>(
  {
    required,
    onFocus,
    selectAllTextOnFocus,
    autoFocus,
    error,
    hideErrorMessage,
    labelProps,
    descriptionProps,
    size,
    inputContainer,
    showSkeleton,
    clearable,
    ...rest
  }: FormSelectProps<TOption>,
  forwardedRef: ForwardedRef<HTMLButtonElement>
) {
  return (
    <InputRenderer<HTMLButtonElement>
      ref={forwardedRef}
      error={error}
      hideErrorMessage={hideErrorMessage}
      autoFocus={autoFocus}
      onFocus={onFocus}
      size={size}
      showSkeleton={showSkeleton}
      selectAllTextOnFocus={selectAllTextOnFocus}
      descriptionProps={descriptionProps}
      labelProps={labelProps}
      inputContainer={inputContainer}
    >
      {(p, ref) => (
        <Select<TOption>
          ref={ref}
          withAsterisk={required}
          clearable={clearable ?? !required}
          {...rest}
          {...p}
        />
      )}
    </InputRenderer>
  );
}

export const FormSelect = forwardRef(_FormSelect) as <
  T extends OptionModel = OptionModel
>(
  props: FormSelectProps<T> & { ref?: ForwardedRef<HTMLInputElement> }
) => ReturnType<typeof _FormSelect>;

import {
  Box as MantineBox,
  ColorSwatch as MantineColorSwatch,
  ElementProps
} from '@mantine/core';
import React, { forwardRef } from 'react';

import { Tooltip } from '@/common/components/Tooltip';
import { ColorPaletteItem } from '@/common/models/ColorPalette';

import { useColorPickerContext } from '../ColorPickerElement.context';

export interface SwatchesProps extends ElementProps<'div'> {
  size?: string | number;
  data: ColorPaletteItem[];
  swatchesPerRow?: number;
  focusable?: boolean;
  onChangeEnd?: (color: string) => void;
  setValue: (value: string) => void;
}

export const Swatches = forwardRef<HTMLDivElement, SwatchesProps>(
  (
    {
      className,
      datatype,
      setValue,
      onChangeEnd,
      size,
      focusable,
      data,
      swatchesPerRow,
      ...others
    },
    ref
  ) => {
    const ctx = useColorPickerContext()!;

    const colors = data.map((c, index) => (
      <Tooltip label={c.label || c.value} key={c.id.toString()}>
        <MantineColorSwatch
          {...ctx.getStyles('swatch')}
          unstyled={ctx.unstyled}
          component="button"
          type="button"
          color={c.value}
          key={index}
          radius="sm"
          onClick={() => {
            setValue(c.value);
            onChangeEnd?.(c.value);
          }}
          aria-label={c.value}
          tabIndex={focusable ? 0 : -1}
          data-swatch
        />
      </Tooltip>
    ));

    return (
      <MantineBox {...ctx.getStyles('swatches')} ref={ref} {...others}>
        {colors}
      </MantineBox>
    );
  }
);

Swatches.displayName = 'ColorPickerElementSwatches';

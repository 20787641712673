import { createOptionalContext, GetStylesApi } from '@mantine/core';

import type { ColorPickerElementFactory } from './ColorPickerElement';

interface ColorPickerContextValue {
  getStyles: GetStylesApi<ColorPickerElementFactory>;
  unstyled: boolean | undefined;
}

export const [ColorPickerProvider, useColorPickerContext] =
  createOptionalContext<ColorPickerContextValue>(null);

import isNil from 'lodash/isNil';

import { OptionModel } from '@/common/models/OptionModel';

import { IAmFormHookField } from '../_FormHookField';
import { FormSelect, FormSelectProps } from './FormSelect';

export interface FormHookSelectProps<TOption extends OptionModel = OptionModel>
  extends IAmFormHookField,
    Omit<
      FormSelectProps<TOption>,
      'required' | 'name' | 'value' | 'defaultValue' | 'error' | 'disabled'
    > {}

export function FormHookSelect<TOption extends OptionModel = OptionModel>(
  props: FormHookSelectProps<TOption>
) {
  const { hook, onBlur, onChange, ...rest } = props;
  return (
    <FormSelect<TOption>
      {...rest}
      required={hook.required}
      name={hook.field.name}
      ref={hook.field.ref}
      disabled={hook.disabled}
      onBlur={(e) => {
        hook.field.onBlur();
        onBlur?.(e);
      }}
      onChange={(v, o) => {
        hook.field.onChange(v);
        onChange?.(v, o);
      }}
      value={!isNil(hook.field.value) ? hook.field.value : ''}
      error={hook.showError ? hook.errorMessage : undefined}
    />
  );
}

import {
  Combobox as MantineCombobox,
  getFontSize as getMantineFontSize,
  Highlight as MantineHighlight,
  MantineSize
} from '@mantine/core';
import isString from 'lodash/isString';
import { FC, ReactNode } from 'react';

import { OptionModel } from '@/common/models/OptionModel';

import classes from './_Components.module.css';
import { isValueChecked } from './_functions';
import {
  OptionsData,
  OptionsGroup,
  SelectOptionItemComponentProps
} from './_types';

export interface SelectOptionsProps<TOption extends OptionModel = OptionModel>
  extends Omit<SelectOptionProps<TOption>, 'data' | 'index'> {
  data: OptionsData<TOption>;
}

export function SelectOptions<TOption extends OptionModel = OptionModel>({
  data,
  value,
  component: itemComponent,
  size
}: SelectOptionsProps<TOption>) {
  return (
    <>
      {(data || []).map((item) => (
        <SelectOption
          data={item}
          key={'items' in item && 'group' in item ? item.group : item.value}
          value={value}
          size={size}
          component={itemComponent}
        />
      ))}
    </>
  );
}

export interface SelectOptionProps<TOption extends OptionModel = OptionModel> {
  data: TOption | OptionsGroup<TOption>;
  value?: string | string[] | null;
  component?: SelectOptionItemComponentProps<TOption>;
  size?: MantineSize;
  titleContainerStackMultiplier?: number;
}

export function SelectOption<TOption extends OptionModel = OptionModel>({
  data,
  value,
  component,
  size = 'sm'
}: SelectOptionProps<TOption>) {
  if (!('items' in data)) {
    const titleFz = getMantineFontSize(size);
    const isSelected = isValueChecked(value, data.value);

    return (
      <MantineCombobox.Option
        value={data.value}
        __vars={{
          '--select-option-item-title-fz': titleFz,
          '--select-option-item-description-fz': `calc(${titleFz} * 0.85)`,
          '--select-option-item-icon-size': titleFz
        }}
        disabled={data.disabled}
        className={classes.option}
        data-checked={isSelected ? 'true' : 'false'}
        aria-selected={isSelected ? 'true' : 'false'}
        aria-disabled={data.disabled ? 'true' : 'false'}
      >
        {!!component ? (
          <_ItemComponent
            item={data}
            component={component}
            isSelected={isSelected}
            size={size}
          />
        ) : (
          <span>{data.label}</span>
        )}
      </MantineCombobox.Option>
    );
  }

  return (
    <MantineCombobox.Group label={data.group}>
      {data.items.map((item) => (
        <SelectOption<TOption>
          key={item.value}
          data={item}
          size={size}
          value={value}
          component={component}
        />
      ))}
    </MantineCombobox.Group>
  );
}

interface _ItemComponentProps<TOption extends OptionModel = OptionModel> {
  item: TOption;
  size: MantineSize;
  isSelected: boolean;
  searchValue?: string;
  component?: SelectOptionProps<TOption>['component'];
}
function _ItemComponent<TOption extends OptionModel = OptionModel>({
  item,
  size,
  isSelected,
  searchValue,
  component
}: _ItemComponentProps<TOption>) {
  const { title, description, icon, render } = component;

  const hasRender = !!render;
  const hasDescription = !!description;
  const hasTitle = !!title;
  const hasIcon = !!icon;

  return (
    <>
      {hasRender ? (
        render(item, { isSelected, size })
      ) : (
        <SelectOptionComponent
          highlightValue={searchValue}
          icon={hasIcon ? icon(item, { isSelected, size }) : undefined}
          label={hasTitle ? title(item, { isSelected, size }) : item.label}
          description={
            hasDescription ? description(item, { isSelected, size }) : undefined
          }
        />
      )}
    </>
  );
}

export type SelectOptionComponentProps = {
  icon?: ReactNode;
  label: ReactNode;
  description?: ReactNode;
  highlightValue?: string;
  highlightLabel?: boolean;
  highlightDescription?: boolean;
};

export const SelectOptionComponent: FC<SelectOptionComponentProps> = ({
  icon,
  label,
  description,
  highlightLabel: highlightLabelProp = true,
  highlightDescription: highlightDescriptionProp = true,
  highlightValue
}) => {
  const highlightLabel = highlightLabelProp && isString(label);
  const highlightDescription =
    highlightDescriptionProp && isString(description);
  return (
    <div className={classes.optionItemContainer}>
      {!!icon && <div className={classes.optionItemIcon}>{icon}</div>}
      <div className={classes.optionItemTextContainer}>
        {highlightLabel ? (
          <MantineHighlight
            component="div"
            highlight={highlightValue || ''}
            className={classes.optionItemTitle}
          >
            {label}
          </MantineHighlight>
        ) : (
          <div className={classes.optionItemTitle}>{label}</div>
        )}

        {!!description && (
          <>
            {highlightDescription ? (
              <MantineHighlight
                component="div"
                highlight={highlightValue || ''}
                className={classes.optionItemDescription}
              >
                {description}
              </MantineHighlight>
            ) : (
              <div className={classes.optionItemDescription}>{description}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

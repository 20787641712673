import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const HeadingIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          fill="none"
          strokeWidth={'1.5'}
          stroke={color || getDefaultColor()}
          d="M7 12h10"
        />
        <path
          fill="none"
          strokeWidth={'1.5'}
          stroke={color || getDefaultColor()}
          d="M7 4v16"
        />
        <path
          fill="none"
          strokeWidth={'1.5'}
          stroke={color || getDefaultColor()}
          d="M17 4v16"
        />
        <path
          fill="none"
          strokeWidth={'1.5'}
          stroke={color || getDefaultColor()}
          d="M15 20h4"
        />
        <path
          fill="none"
          strokeWidth={'1.5'}
          stroke={color || getDefaultColor()}
          d="M15 4h4"
        />
        <path
          fill="none"
          strokeWidth={'1.5'}
          stroke={color || getDefaultColor()}
          d="M5 20h4"
        />
        <path
          fill="none"
          strokeWidth={'1.5'}
          stroke={color || getDefaultColor()}
          d="M5 4h4"
        />
      </IconSvg>
    );
  }
);

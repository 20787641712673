import { FC } from 'react';

interface Props {
  disabled?: boolean;
}

/**
 * if a html form element does not contain an input/button of type submit
 * when you hit enter on a regular input it will not submit the form.
 * One use case where we cant have a submit input in the form is modals
 * as the submit button needs to sit in the ModalFooter. This will give us
 * that functionality if this is rendered as a child of the form element
 */
export const FormHiddenSubmit: FC<Props> = ({ disabled }) => {
  return (
    <input type="submit" disabled={disabled} style={{ display: 'none' }} />
  );
};

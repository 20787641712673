import { FC } from 'react';

import { BorderStyleIcon } from '@/common/components/Icons/BorderStyleIcon';
import { DropletIcon } from '@/common/components/Icons/DropletIcon';
import { HeadingIcon } from '@/common/components/Icons/HeadingIcon';
import { TextActiveIcon } from '@/common/components/Icons/TextActiveIcon';
import { TextAltIcon } from '@/common/components/Icons/TextAltIcon';

import { IconProps } from '../Icons/IconProps';

export type ColorPickerIconVariants =
  | 'text'
  | 'textActive'
  | 'heading'
  | 'droplet'
  | 'border';

export type ColorPickerIconProps = {
  variant?: ColorPickerIconVariants;
  size?: IconProps['size'];
};
export const ColorPickerIcon: FC<ColorPickerIconProps> = ({
  variant,
  size
}) => {
  const p = { size };

  switch (variant) {
    case 'text':
      return <TextAltIcon {...p} />;
    case 'textActive':
      return <TextActiveIcon {...p} />;
    case 'heading':
      return <HeadingIcon {...p} />;
    case 'border':
      return <BorderStyleIcon {...p} />;
    case 'droplet':
    default:
      return <DropletIcon {...p} />;
  }
};

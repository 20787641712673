import {
  __BaseInputProps as __MantineBaseInputProps,
  __CloseButtonProps as __MantineCloseButtonProps,
  __InputStylesNames as __MantineInputStylesNames,
  BoxProps as MantineBoxProps,
  Combobox as MantineCombobox,
  ComboboxLikeProps as MantineComboboxLikeProps,
  ComboboxLikeStylesNames as MantineComboboxLikeStylesNames,
  ElementProps as MantineElementProps,
  Factory as MantineFactory,
  factory as mantineFactory,
  InputBase as MantineInputBase,
  InputPlaceholder as MantineInputPlaceholder,
  InputVariant as MantineInputVariant,
  MantineSize,
  StylesApiProps as MantineStylesApiProps,
  useCombobox as useMantineCombobox,
  useProps as useMantineProps,
  useResolvedStylesApi as useMantineResolvedStylesApi
} from '@mantine/core';
import { useId as useMantineId } from '@mantine/hooks';
import cx from 'clsx';
import isNil from 'lodash/isNil';
import React, { ForwardedRef, ReactNode, useEffect, useMemo } from 'react';

import { SearchIcon } from '@/common/components/Icons/SearchIcon';
import { useUncontrolled } from '@/common/hooks/useUncontrolled';
import { OptionModel } from '@/common/models/OptionModel';

import classes from './_Components.module.css';
import {
  customSelectSearchFilter,
  getOptionsLockup,
  groupOptions
} from './_functions';
import { FilterOptionsInput } from './_types';
import { SelectOptionsDropdown } from './Select.Dropdown';
import { SelectOptionProps, SelectOptions } from './Select.Option';

export type SelectStylesNames =
  | __MantineInputStylesNames
  | MantineComboboxLikeStylesNames;

export interface SelectProps<TOption extends OptionModel = OptionModel>
  extends MantineBoxProps,
    Omit<__MantineBaseInputProps, 'size'>,
    Omit<
      MantineComboboxLikeProps,
      'data' | 'selectFirstOptionOnChange' | 'filter'
    >,
    Omit<MantineStylesApiProps<SelectFactory>, 'unstyled'>,
    Pick<
      MantineElementProps<'input'>,
      'name' | 'form' | 'id' | 'readOnly' | 'placeholder'
    >,
    Pick<MantineElementProps<'button'>, 'onClick' | 'onFocus' | 'onBlur'> {
  size?: MantineSize;
  /** The combobox options */
  data: TOption[];

  /** Controlled component value */
  value?: TOption['value'] | null;

  /** Uncontrolled component default value */
  defaultValue?: TOption['value'] | null;

  /** Called when value changes */
  onChange?: (value: TOption['value'] | null, option: TOption | null) => void;

  /** Determines whether the select should be searchable, `false` by default */
  searchable?: boolean;

  /** Message displayed when no option matched current search query, only applicable when `searchable` prop is set */
  nothingFoundMessage?: React.ReactNode;

  /** Controlled search value */
  searchValue?: string;

  /** Default search value */
  defaultSearchValue?: string;

  /** Called when search changes */
  onSearchChange?: (value: string) => void;

  /** Determines whether it should be possible to deselect value by clicking on the selected option, `true` by default */
  allowDeselect?: boolean;

  /** Determines whether the clear button should be displayed in the right section when the component has value, `false` by default */
  clearable?: boolean;

  /** Props passed down to the clear button */
  clearButtonProps?: __MantineCloseButtonProps & MantineElementProps<'button'>;

  /** Props passed down to the hidden input */
  hiddenInputProps?: React.ComponentPropsWithoutRef<'input'>;

  itemComponent?: SelectOptionProps<TOption>['component'];

  valueIcon?: (value: TOption) => ReactNode;

  filter?: FilterOptionsInput<TOption>['predicate'];
  filterProps?: Pick<FilterOptionsInput, 'splitWords' | 'ignoreCase'>;

  searchPlaceholder?: string;
}

export type SelectFactory<TOption extends OptionModel = OptionModel> =
  MantineFactory<{
    props: SelectProps<TOption>;
    ref: HTMLButtonElement;
    stylesNames: SelectStylesNames;
    variant: MantineInputVariant;
  }>;

const defaultProps: Partial<SelectProps> = {
  allowDeselect: false,
  withScrollArea: true,
  searchPlaceholder: 'Search...',
  leftSectionPointerEvents: 'none'
};

function _Select<TOption extends OptionModel = OptionModel>(
  _props: SelectProps<TOption>,
  ref: ForwardedRef<SelectFactory<TOption>['ref']>
) {
  const props = useMantineProps(
    'Select',
    defaultProps as any,
    _props
  ) as SelectProps<TOption>;
  const {
    classNames,
    styles,
    vars,
    dropdownOpened,
    defaultDropdownOpened,
    onDropdownClose,
    onDropdownOpen,
    onFocus,
    onBlur,
    onClick,
    onChange,
    data = [],
    value: valueProp,
    defaultValue,
    onOptionSubmit,
    comboboxProps: comboboxPropsProp,
    readOnly,
    disabled,
    filter,
    filterProps,
    limit: limitProp,
    withScrollArea,
    maxDropdownHeight,
    size,
    searchable: searchableProp,
    rightSection,
    nothingFoundMessage,
    name,
    form,
    searchValue,
    defaultSearchValue,
    onSearchChange,
    allowDeselect,
    error,
    rightSectionPointerEvents,
    id,
    clearable,
    clearButtonProps: clearButtonPropsProp,
    hiddenInputProps,
    itemComponent,
    leftSection,
    valueIcon,
    searchPlaceholder,
    placeholder,
    ...others
  } = props;

  const parsedData = useMemo(() => groupOptions<TOption>(data), [data]);
  const optionsLockup = useMemo(
    () => getOptionsLockup(parsedData) as Record<string, TOption>,
    [parsedData]
  );
  const _id = useMantineId(id);

  //Turn on searchable if not set and have more that 10 items
  let searchable = searchableProp;
  if (isNil(searchable) && (data || []).length > 10) {
    searchable = true;
  }

  //Until we implement virtualization we need to limit the options to 100 max
  let limit = limitProp;
  if (data.length > 100 && isNil(limit)) {
    limit = 100;
  }

  const [_value, setValue] = useUncontrolled<string, TOption>({
    value: valueProp,
    defaultValue,
    finalValue: null,
    resolveValue: (v) => v?.value,
    onChange: (cv, v) => onChange?.(v, cv)
  });

  const selectedOption =
    typeof _value === 'string' ? optionsLockup[_value] : undefined;
  const hasSelectedOption = !!selectedOption;
  const [search, setSearch] = useUncontrolled({
    value: searchValue,
    defaultValue: defaultSearchValue,
    finalValue: hasSelectedOption ? selectedOption.label : '',
    onChange: onSearchChange
  });

  const combobox = useMantineCombobox({
    opened: dropdownOpened,
    defaultOpened: defaultDropdownOpened,
    onDropdownOpen: () => {
      onDropdownOpen?.();
      combobox.updateSelectedOptionIndex('active', { scrollIntoView: true });
      if (searchable) {
        setTimeout(() => {
          combobox.focusSearchInput();
        }, 50);
      }
    },
    onDropdownClose: () => {
      onDropdownClose?.();
      combobox.resetSelectedOption();
      if (searchable) {
        setSearch('');
      }
    }
  });

  const { resolvedClassNames, resolvedStyles } = useMantineResolvedStylesApi<
    SelectFactory<TOption>
  >({
    props,
    styles,
    classNames
  });

  useEffect(() => {
    if (valueProp === null) {
      setSearch('');
    }

    if (typeof valueProp === 'string' && selectedOption) {
      setSearch(selectedOption.label);
    }
  }, [valueProp, selectedOption]);

  const clearButtonProps = clearButtonPropsProp ?? {};
  if (clearable) {
    if (!clearButtonProps['aria-label']) {
      clearButtonProps['aria-label'] = 'Clear';
    }
  }

  const clearButton = clearable &&
    !!selectedOption &&
    !disabled &&
    !readOnly && (
      <MantineCombobox.ClearButton
        size={size as string}
        {...clearButtonProps}
        onClear={() => {
          setValue(null);
          setSearch('');
        }}
      />
    );

  const resolveLeftSection = () => {
    if (!!leftSection) {
      return leftSection;
    }

    return !selectedOption ? undefined : valueIcon?.(selectedOption);
  };

  const comboboxProps = comboboxPropsProp ?? {};
  if (isNil(comboboxProps.withinPortal)) {
    comboboxProps.withinPortal = true;
  }

  if (comboboxProps.withinPortal && !comboboxProps.zIndex) {
    //fix for showing above modals
    comboboxProps.zIndex = 1100;
  }

  if (isNil(comboboxProps.transitionProps)) {
    comboboxProps.transitionProps = {
      transition: 'pop'
    };
  }

  if (readOnly) {
    others['data-disabled'] = 'true';
    others['aria-readonly'] = 'true';
  }

  return (
    <>
      <MantineCombobox
        store={combobox}
        __staticSelector="Select"
        classNames={resolvedClassNames}
        styles={resolvedStyles}
        readOnly={readOnly}
        onOptionSubmit={(val) => {
          onOptionSubmit?.(val);
          const optionLockup = allowDeselect
            ? optionsLockup[val].value === _value
              ? null
              : optionsLockup[val]
            : optionsLockup[val];

          const nextValue = optionLockup ? optionLockup.value : null;

          setValue(optionLockup);
          setSearch(
            typeof nextValue === 'string' ? optionLockup?.label || '' : ''
          );
          combobox.closeDropdown();
        }}
        size={size}
        {...comboboxProps}
      >
        <MantineCombobox.Target>
          <MantineInputBase
            id={_id}
            component="button"
            type="button"
            ref={ref}
            rightSection={
              rightSection ||
              clearButton || (
                <MantineCombobox.Chevron size={size} error={error} />
              )
            }
            {...others}
            rightSectionPointerEvents={
              !!rightSectionPointerEvents
                ? rightSectionPointerEvents
                : clearButton
                  ? 'all'
                  : 'none'
            }
            size={size}
            __staticSelector="Select"
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            onClick={(event) => {
              combobox.toggleDropdown();
              onClick?.(event);
            }}
            classNames={{
              ...resolvedClassNames,
              input: cx(classes.selectInput, resolvedClassNames?.input)
            }}
            title={hasSelectedOption ? selectedOption.label : undefined}
            styles={resolvedStyles}
            pointer
            leftSection={resolveLeftSection()}
            error={error}
          >
            {hasSelectedOption ? (
              selectedOption.label
            ) : !!placeholder ? (
              <MantineInputPlaceholder>{placeholder}</MantineInputPlaceholder>
            ) : null}
          </MantineInputBase>
        </MantineCombobox.Target>
        <SelectOptionsDropdown
          data={parsedData}
          hidden={readOnly || disabled}
          filter={
            !!filter ? customSelectSearchFilter(filter, filterProps) : undefined
          }
          limit={limit}
          hiddenWhenEmpty={!searchable || !nothingFoundMessage}
          withScrollArea={withScrollArea}
          maxDropdownHeight={maxDropdownHeight}
          filterOptions={searchable && selectedOption?.label !== search}
          nothingFoundMessage={nothingFoundMessage}
          labelId={`${_id}-label`}
          searchProps={
            searchable
              ? {
                  value: search,
                  leftSection: <SearchIcon />,
                  placeholder: searchPlaceholder,
                  onChange: (e) => {
                    setSearch(e.currentTarget.value);
                    if (combobox.dropdownOpened) {
                      combobox.selectFirstOption();
                    }
                  }
                }
              : undefined
          }
        >
          {(_data) => (
            <SelectOptions
              data={_data}
              component={itemComponent}
              value={selectedOption?.value ?? null}
              size={size}
            />
          )}
        </SelectOptionsDropdown>
      </MantineCombobox>
      <input
        type="hidden"
        name={name}
        value={_value || ''}
        form={form}
        disabled={disabled}
        {...hiddenInputProps}
      />
    </>
  );
}

const _FactorySelect = mantineFactory(_Select) as unknown as <
  TOption extends OptionModel = OptionModel
>(
  props: SelectFactory<TOption>['props'] & {
    ref?: ForwardedRef<SelectFactory<TOption>['ref']>;
  }
) => ReturnType<typeof _Select>;

const FactorySelect = _FactorySelect as any;

FactorySelect.classes = {
  ...MantineInputBase.classes,
  ...MantineCombobox.classes
};
FactorySelect.displayName = 'Select';

export const Select = FactorySelect as typeof _FactorySelect;

import dynamic from 'next/dynamic';
import { useRef } from 'react';

import { ModalSizes, useModal } from '@/common/components/Modal';
import { ModalSkeleton } from '@/common/components/Modal/ModalSkeleton';
import { useConstant } from '@/common/hooks/useConstant';
import { useEvent } from '@/common/hooks/useEvent';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { UploadTypes } from '@/common/models/UploadTypes';

import { UseImageModalOptions } from './ImageEditorModal.types';

const ImageEditorModal = dynamic(() => import('./ImageEditorModal'), {
  ssr: false,
  loading: () => (
    <ModalSkeleton
      withFooter={false}
      size={ModalSizes.Normal}
      contentVariant="komo-loader"
    />
  )
});

export const useImageEditorModal = ({
  onChange,
  onClose,
  onSignAsync,
  size,
  canRemove,
  acceptTypes,
  ignoreSizeOnReadUrl,
  disabled,
  hiddenTabs,
  modalTitle
}: UseImageModalOptions) => {
  const valueRef = useRef<ImageDataModel>();
  const handleChange = useEvent(onChange);
  const handleClose = useEvent(onClose);
  const handleSignAsync = useEvent(onSignAsync);

  const [show, hide] = useModal(() => {
    return (
      <ImageEditorModal
        size={size}
        value={valueRef.current}
        canRemove={canRemove}
        modalTitle={modalTitle}
        onSignAsync={handleSignAsync}
        disabled={disabled}
        hiddenTabs={hiddenTabs}
        ignoreSizeOnReadUrl={ignoreSizeOnReadUrl}
        acceptTypes={
          acceptTypes || [UploadTypes.png, UploadTypes.jpeg, UploadTypes.gif]
        }
        onClose={() => {
          handleClose();
          hide();
        }}
        onChange={(x) => {
          handleChange(x);
          hide();
        }}
      />
    );
  }, [
    size,
    canRemove,
    modalTitle,
    disabled,
    hiddenTabs,
    ignoreSizeOnReadUrl,
    acceptTypes
  ]);

  return useConstant(() => ({
    show: (image?: ImageDataModel) => {
      valueRef.current = image;
      show();
    }
  }));
};
